import React, { useState } from "react";
import "./Header.css";

// Images
import headerLogo from "../../assets/elitede.svg";
import mobileHeaderLogo from "../../assets/elitemo.svg";

// Dependencies
import { Link, useNavigate } from "react-router-dom";

// Components
import Button from "../Button/Button";

// Icons
import { IoMdClose } from "react-icons/io";
import { AiOutlineMenu } from "react-icons/ai";

const Header = () => {
  const [visibility, setVisibility] = useState(false);
  const navigate = useNavigate();

  const toggleVisibility = () => setVisibility(!visibility);

  return (
    <header>
      <div className="header_logo_sect">
        <img src={headerLogo} alt="header logo" />
        <img
          className="mobile_logo"
          src={mobileHeaderLogo}
          alt="mobile header logo"
        />
      </div>
      <nav className="header_navigation">
        <Link to={"/"}>Home</Link>
        <Link to={"/exchange"}>Exchange</Link>
        <Link to={"/partners"}>Partners</Link>
        <Link to={"/prices"}>Prices</Link>
        <Link to={"/about-us"}>About</Link>
      </nav>
      <nav
        className={`mobile_header_navigation ${visibility ? "show_nav" : ""}`}
      >
        <div className="mobile_header_navigation_header">
          <img
            className="mobile_logo"
            src={mobileHeaderLogo}
            alt="mobile header logo"
          />
          <IoMdClose
            onClick={toggleVisibility}
            className="menu_harm"
            color="var(--secondary-color)"
            size={30}
          />
        </div>
        <Link to={"/"}>Home</Link>
        <Link to={"/exchange"}>Exchange</Link>
        <Link to={"/partners"}>Partners</Link>
        <Link to={"/prices"}>Prices</Link>
        <Link to={"/about-us"}>About</Link>
        <div className="mobile_nav_btn_con">
          <Button
            action={() => navigate("/auth/login")}
            title={"Login"}
            id={"true"}
          />
          <Button
            action={() => navigate("/auth/register")}
            title={"Sign Up"}
            id={"true"}
          />
        </div>
      </nav>
      <div className="header_btn_sect">
        <Button
          id={1}
          title={"Log In"}
          action={() => navigate("/auth/login")}
        />
        <Button
          title={"Sign Up"}
          action={() => navigate("/auth/register")}
          id={"true"}
        />
        <AiOutlineMenu
          onClick={toggleVisibility}
          className="menu_harm"
          color="var(--secondary-color)"
          size={30}
        />
      </div>
    </header>
  );
};

export default Header;
