import React from "react";
import Button from "../Button/Button";
import { useNavigate } from "react-router-dom";

const PageFooter = ({ title, text }) => {
  const navigate = useNavigate();

  return (
    <div className="about_last_sect">
      <h2 className="home_header_text">{title}</h2>
      <p className="text_text">{text}</p>
      <Button title={"Sign Up"} action={() => navigate("/auth/register")} />
    </div>
  );
};

export default PageFooter;
