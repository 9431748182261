import React from "react";
import "./Transaction.css";
import DashboardHeader from "../DashboardHeader/DashboardHeader";
import { useGlobalContext } from "../../../context/context";
import { useEffect } from "react";
import moment from "moment";
import Button from "../../Btn/Btn";
const Transaction = () => {
  const accessToken = JSON.parse(sessionStorage.getItem("accessToken"));

  const {
    userDetails,
    getUser,
    getAllDeposits,
    depositHistory,
    withdrawHistory,
    getAllWithdrawals,
    depositLoading,
    withdrawLoading,
  } = useGlobalContext();
  const { firstName } = userDetails;
  const symbol = "$";

  useEffect(() => {
    getAllDeposits(accessToken);
    getAllWithdrawals(accessToken);
    getUser(accessToken);
  }, []);

  return (
    <section>
      <DashboardHeader pathname={firstName} />
      <div className="transaction_sect">
        <h2>All Deposit History</h2>
        <div className="transaction_table">
          <div className="transaction_table_head">
            <div className="date">
              <h3>Date/Time</h3>
            </div>
            <div className="type">
              <h3>Type</h3>
            </div>
            <div className="amount">
              <h3>Amount</h3>
            </div>
            <div className="status">
              <h3>Status</h3>
            </div>
            <div className="reference">
              <h3>Updated At</h3>
            </div>
          </div>
          {depositLoading ? (
            <div>
              <h3>Loading...</h3>
            </div>
          ) : (
            depositHistory?.map((item, index) => {
              const { createdAt, status, transactiontype, amount, updatedAt } =
                item;

              return (
                <div key={index} className="transaction_table_body">
                  <div className="date">
                    <p>{moment(createdAt).format("MMMM Do YYYY, h:mm")}</p>
                  </div>
                  <div className="type">
                    <p>
                      {transactiontype === "crypto"
                        ? "Payment through crypto"
                        : "Bank Transfer"}
                    </p>
                  </div>
                  <div className="amount">
                    <p>
                      {symbol}
                      {amount}
                    </p>
                  </div>
                  <div className="status">
                    <Button
                      title={status ? "Successful" : "Pending"}
                      width={100}
                      height={30}
                      background={!status ? "#FFF3E7" : "#EDFFF9"}
                      color={!status ? "#999DA1" : "#27AE61"}
                    />
                  </div>
                  <div className="reference">
                    <p>{moment(updatedAt).format("MMMM Do YYYY, h:mm")}</p>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
      <div className="transaction_sect">
        <h2>All Withdrawal History</h2>
        <div className="transaction_table">
          <div className="transaction_table_head">
            <div className="date">
              <h3>Date/Time</h3>
            </div>
            <div className="type">
              <h3>Type</h3>
            </div>
            <div className="amount">
              <h3>Amount</h3>
            </div>
            <div className="status">
              <h3>Status</h3>
            </div>
            <div className="reference">
              <h3>Reference</h3>
            </div>
          </div>
          {withdrawLoading ? (
            <div>
              <h3>Loading...</h3>
            </div>
          ) : (
            withdrawHistory?.map((item, index) => {
              const { createdAt, status, accountType, amount, _id } = item;
              return (
                <div key={index} className="transaction_table_body">
                  <div className="date">
                    <p>{moment(createdAt).format("MMMM Do YYYY, h:mm")}</p>
                  </div>
                  <div className="type">
                    <p>{accountType}</p>
                  </div>
                  <div className="amount">
                    <p>
                      {symbol}
                      {amount}
                    </p>
                  </div>
                  <div className="status">
                    <Button
                      title={!status ? "Pending" : "Approved"}
                      width={100}
                      height={30}
                      background={!status ? "#FFF3E7" : "approved"}
                      color={
                        status === "pending"
                          ? "#999DA1"
                          : status === "approved"
                          ? "27AE61"
                          : "#FC6121"
                      }
                    />
                  </div>
                  <div className="reference">
                    <p>{_id}</p>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </section>
  );
};

export default Transaction;
