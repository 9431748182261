import React, { useState } from "react";

// Components
import { Button, Footer, Header } from "../../components";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useGlobalContext } from "../../context/context";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const name = "Elitecryptography";

  const { baseUrl } = useGlobalContext();
  const { handleSubmit, register } = useForm();
  const [loading, setLoading] = useState(false);

  const handleResetPassword = (data) => {
    setLoading(true);
    axios
      .post(`${baseUrl}auth/forget`, { email: data.email })
      .then((data) => {
        toast.success("Please check email for password reset link");
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.data === "Wrong credentials") {
          toast.error("Wrong credentials");
        }
      });
  };

  return (
    <>
      <Header />
      <section className="register_container login_container forget_password">
        <h2 className="text_2">Forgot Password</h2>
        <p className="terms">
          Please enter the email address associated with your {name} account
          below. Resetting a forgotten password will logout other devices and
          will result in a 72-hour hold on cryptocurrency withdrawals.
        </p>
        <form
          className="register_form"
          onSubmit={handleSubmit((data) => handleResetPassword(data))}
        >
          <div>
            <p className="text_text">Email address</p>
            <input
              {...register("email")}
              type="text"
              className="form_input"
              placeholder="name@email.com"
            />
          </div>
          <Button
            title={loading ? "Loading..." : "Continue"}
            action={() => ""}
          />
        </form>
      </section>
      <ToastContainer />
      <Footer />
    </>
  );
};

export default ForgetPassword;
