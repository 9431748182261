import React, { Fragment } from "react";
import DashboardHeader from "../DashboardHeader/DashboardHeader";
import "./Wallet.css";
import { MdHistory } from "react-icons/md";
import { Button, WalletHeader } from "../../../components";
import { RiLuggageDepositLine } from "react-icons/ri";
import { BiMoneyWithdraw } from "react-icons/bi";
import { Example } from "../Chart/Example";
import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";
import { Link } from "react-router-dom";
import { FiSettings } from "react-icons/fi";
import { useGlobalContext } from "../../../context/context";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Wallet = () => {
  const { btcLoading, userDetails } = useGlobalContext();

  const { firstName } = userDetails;
  const user = JSON.parse(sessionStorage.getItem("user"));
  const otherName = user?.firstName;

  return (
    <section className="wallet_sect">
      <DashboardHeader pathname={`${!firstName ? otherName : firstName}`} />
      <div className="account_total_sect">
        <WalletHeader />
        <div className="account_total_item_two">
          <Link to={"/deposit"}>
            {btcLoading ? (
              <Skeleton width={60} height={35} style={{ borderRadius: 50 }} />
            ) : (
              <Button
                background={"#162af1"}
                color={"white"}
                icon={<RiLuggageDepositLine size={20} />}
                width={75}
                height={50}
                action={() => ""}
              />
            )}
            {btcLoading ? (
              <Skeleton width={60} height={15} style={{ borderRadius: 50 }} />
            ) : (
              <p>Deposit</p>
            )}
          </Link>
          <Link to={"/withdrawal"}>
            {btcLoading ? (
              <Skeleton width={60} height={35} style={{ borderRadius: 50 }} />
            ) : (
              <Button
                background={"#162af1"}
                color={"white"}
                icon={<BiMoneyWithdraw size={20} />}
                width={75}
                height={50}
                action={() => ""}
              />
            )}
            {btcLoading ? (
              <Skeleton width={60} height={15} style={{ borderRadius: 50 }} />
            ) : (
              <p>Withdraw</p>
            )}
          </Link>
          <Link to={"/settings"}>
            {btcLoading ? (
              <Skeleton width={60} height={35} style={{ borderRadius: 50 }} />
            ) : (
              <Button
                background={"#162af1"}
                color={"white"}
                icon={<FiSettings size={20} />}
                width={75}
                height={50}
                action={() => ""}
              />
            )}
            {btcLoading ? (
              <Skeleton width={60} height={15} style={{ borderRadius: 50 }} />
            ) : (
              <p>Settings</p>
            )}
          </Link>
          <Link to={"/transactions"}>
            {btcLoading ? (
              <Skeleton width={60} height={35} style={{ borderRadius: 50 }} />
            ) : (
              <Button
                background={"#162af1"}
                color={"white"}
                icon={<MdHistory size={20} />}
                width={75}
                height={50}
                action={() => ""}
              />
            )}
            {btcLoading ? (
              <Skeleton width={60} height={15} style={{ borderRadius: 50 }} />
            ) : (
              <p>Transaction</p>
            )}
          </Link>
        </div>
      </div>
      {btcLoading ? (
        <div style={{ width: "90%", margin: "auto" }}>
          <Skeleton height={500} />
        </div>
      ) : (
        <Fragment>
          <AdvancedRealTimeChart theme="dark" width="100%" height={600} />
        </Fragment>
      )}
      <Example />
    </section>
  );
};

export default Wallet;
