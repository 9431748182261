import React, { useState } from "react";
import "./Register.css";
import { Link, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import axios from "axios";

// Components
import { Button, Footer, Header } from "../../components";

// Icons
import {
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdVisibility,
} from "react-icons/md";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { useGlobalContext } from "../../context/context";
import { ToastContainer, toast } from "react-toastify";

const schema = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  middleName: yup.string().required(),
  country: yup.string().required(),
  email: yup.string().email().required(),
  phone: yup.string().required(),
  password: yup.string().min(6).max(14).required(),
  confirmpassword: yup
    .string()
    .required("Please confirm your password")
    .oneOf([yup.ref("password"), null], "Password must matched"),
});

const Register = () => {
  const name = "Elitecryptography";
  const { baseUrl } = useGlobalContext();
  const [checkbox, setCheckbox] = useState(false);
  const [loading, setLoading] = useState(false);
  const [waiLoading, setWaiLoading] = useState(false);
  const [visible, setVisible] = useState(true);
  const toggleVisibility = () => setVisible(!visible);
  const toggleCheckBox = () => setCheckbox(!checkbox);

  const navigate = useNavigate();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const handleRegisterForm = async (data) => {
    setLoading(true);
    setTimeout(() => {
      setWaiLoading(true);
    }, 10000);

    try {
      const response = await axios.post(
        `${baseUrl}auth/register`,

        data
      );
      if (response.status === 201) {
        setLoading(false);
        setWaiLoading(false);
        toast.success("Registration Successful");
        setTimeout(() => {
          navigate("/auth/login");
        }, 2000);
      }
    } catch (error) {
      setLoading(false);
      setWaiLoading(false);
      if (error.response.data.keyValue.email === data.email) {
        toast.error("User Already Exist");
      }
    }
  };

  return (
    <>
      <Header />
      <section className="register_container">
        <h2 className="text_2">Let get you started with a {name} account</h2>
        <form
          className="register_form"
          onSubmit={handleSubmit((data) => handleRegisterForm(data))}
        >
          <div>
            <p className="text_text">Given Name</p>
            <input
              type="text"
              className="form_input"
              placeholder="Given Name"
              {...register("firstName", { required: "First Name is Required" })}
            />
            <h4 className="error">{errors.firstName?.message}</h4>
          </div>
          <div>
            <p className="text_text">Middle Name</p>
            <input
              type="text"
              className="form_input"
              placeholder="Middle Name"
              {...register("middleName", { required: true })}
            />
            <h4 className="error">{errors.middleName?.message}</h4>
          </div>
          <div>
            <p className="text_text">Family Name</p>
            <input
              type="text"
              className="form_input"
              placeholder="Family Name"
              {...register("lastName", { required: true })}
            />
            <h4 className="error">{errors.lastName?.message}</h4>
          </div>
          <div>
            <p className="text_text">Email</p>
            <input
              type="text"
              className="form_input"
              placeholder="name@email.com"
              {...register("email", { required: true })}
            />
            <h4 className="error">{errors.email?.message}</h4>
          </div>
          <div>
            <p className="text_text">Country</p>
            <input
              type="text"
              className="form_input"
              placeholder="Country"
              {...register("country", { required: true })}
            />
            <h4 className="error">{errors.country?.message}</h4>
          </div>
          <div>
            <p className="text_text">Mobile Number</p>
            <input
              type="text"
              className="form_input"
              placeholder="Mobile Number"
              {...register("phone", { required: true })}
            />
            <h4 className="error">{errors.phone?.message}</h4>
          </div>
          <div>
            <p className="text_text">Password</p>
            <div className="register_password form_input">
              <input
                type={visible ? "text" : "password"}
                placeholder="Your Password"
                {...register("password", { required: true })}
              />
              {visible ? (
                <AiOutlineEyeInvisible size={20} onClick={toggleVisibility} />
              ) : (
                <MdVisibility size={20} onClick={toggleVisibility} />
              )}
            </div>
            <h4 className="error">{errors.password?.message}</h4>
          </div>
          <div>
            <p className="text_text">Confirm Password</p>
            <div className="register_password form_input">
              <input
                type={visible ? "text" : "password"}
                placeholder="Confirm Password"
                {...register("confirmpassword", { required: true })}
              />
              {visible ? (
                <AiOutlineEyeInvisible size={20} onClick={toggleVisibility} />
              ) : (
                <MdVisibility size={20} onClick={toggleVisibility} />
              )}
            </div>
            <h4 className="error">{errors.confirmpassword?.message}</h4>
          </div>
          <div className="terms_container">
            {checkbox ? (
              <MdCheckBox onClick={toggleCheckBox} size={30} />
            ) : (
              <MdCheckBoxOutlineBlank onClick={toggleCheckBox} size={30} />
            )}
            <p className="terms">
              Our User Agreement has changed, including the Dispute Resolution
              provision. By creating this account, you agree to our{" "}
              <Link>USER AGREEMENT</Link> and
              <Link to={"/privacy"}> PRIVACY POLICY</Link>.
            </p>
          </div>
          <Button
            title={
              loading ? "Loading..." : waiLoading ? "Please wait..." : "Sign Up"
            }
            id={""}
            action={() => ""}
          />
        </form>
      </section>
      <ToastContainer />
      <Footer />
    </>
  );
};

export default Register;
