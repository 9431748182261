import React, { useState } from "react";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { MdVisibility } from "react-icons/md";
import { Button, Footer, Header } from "../../components";
import { useForm } from "react-hook-form";
import { useGlobalContext } from "../../context/context";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const NewPassword = () => {
  const [visible, setVisible] = useState(true);
  const toggleVisibility = () => setVisible(!visible);
  const { baseUrl } = useGlobalContext();
  const { handleSubmit, register } = useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  const handleResetPassword = (data) => {
    data.resetToken = id;
    setLoading(true);
    axios
      .post(`${baseUrl}auth/reset`, data)
      .then((data) => {
        toast.success("Password Changed Successfully");
        setTimeout(() => {
          navigate("/auth/login");
        }, 2000);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  return (
    <>
      <Header />
      <section className="register_container login_container forget_password">
        <h2 className="text_2">Enter New Password</h2>
        <form
          className="register_form"
          onSubmit={handleSubmit((data) => handleResetPassword(data))}
        >
          <div>
            <p className="text_text">New Password</p>
            <div className="register_password form_input">
              <input
                type={visible ? "text" : "password"}
                placeholder="Your Password"
                {...register("password")}
              />
              {visible ? (
                <AiOutlineEyeInvisible size={20} onClick={toggleVisibility} />
              ) : (
                <MdVisibility size={20} onClick={toggleVisibility} />
              )}
            </div>
          </div>
          <div>
            <p className="text_text">Confirm Password</p>
            <div className="register_password form_input">
              <input
                type={visible ? "text" : "password"}
                placeholder="Your Password"
                {...register("confirmpassword")}
              />
              {visible ? (
                <AiOutlineEyeInvisible size={20} onClick={toggleVisibility} />
              ) : (
                <MdVisibility size={20} onClick={toggleVisibility} />
              )}
            </div>
          </div>
          <Button title={loading ? "Loading..." : "Reset"} action={() => ""} />
        </form>
      </section>
      <ToastContainer />
      <Footer />
    </>
  );
};

export default NewPassword;
