import React from "react";
import "./DashboardHeader.css";
import { Button } from "../../../components";
import { MdBarChart } from "react-icons/md";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useGlobalContext } from "../../../context/context";
import headerLogo from "../../../assets/elitemo.svg";

const DashboardHeader = ({ pathname }) => {
  const [bitcoin, setBitcoin] = useState("");
  const { rate } = bitcoin;
  const { btcLoading, setBtcLoading } = useGlobalContext();

  const getBitcoinPrice = () => {
    setBtcLoading(false);
    axios
      .get("https://api.coindesk.com/v1/bpi/currentprice.json")
      .then((data) => {
        setBitcoin(data.data.bpi.USD);
      })
      .catch((error) => {
        setBtcLoading(false);
      });
  };

  useEffect(() => {
    getBitcoinPrice();
  }, []);

  return (
    <section className="dashboard_header">
      <div>
        <h3 className="dashboard_h3">
          {btcLoading ? (
            <Skeleton width={150} height={40} />
          ) : (
            `Welcome ${pathname}`
          )}
        </h3>
      </div>
      <div>
        <h3 className="dashboard_h3">
          {btcLoading ? (
            <Skeleton count={1} width={150} height={40} />
          ) : (
            ` BTC/USD ${rate}`
          )}
        </h3>
        {btcLoading ? (
          <Skeleton count={1} width={200} height={40} />
        ) : (
          <Button
            border={"#162af1 2px solid"}
            title={"Exchange"}
            icon={<MdBarChart />}
            background={"white"}
            color={"rgb(22,42,241)"}
            width={170}
            action={() => ""}
          />
        )}
      </div>
      <img className="mobile_logo" src={headerLogo} alt="" />
    </section>
  );
};

export default DashboardHeader;
