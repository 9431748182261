import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import {
  About,
  Exchange,
  ForgetPassword,
  Home,
  Login,
  NewPassword,
  Partners,
  Prices,
  Privacy,
  Register,
  Terms,
} from "./pages";
import { Dashboard } from "./pages/UserDashboard";

function App() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Routes>
      <Route element={<Home />} path="/" />
      <Route element={<About />} path="/about-us" />
      <Route element={<Exchange />} path="/exchange" />
      <Route element={<Privacy />} path="/privacy" />
      <Route element={<Terms />} path="/terms" />
      <Route element={<Register />} path="/auth/register" />
      <Route element={<Login />} path="/auth/login" />
      <Route element={<ForgetPassword />} path="/auth/forget-password" />
      <Route element={<NewPassword />} path="/auth/reset/:id" />
      <Route element={<Prices />} path="/prices" />
      <Route element={<Partners />} path="/partners" />
      <Route element={<Dashboard />} path="*" />
    </Routes>
  );
}

export default App;
