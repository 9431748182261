import React, { useState } from "react";

// Components
import { Button, Footer, Header } from "../../components";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { MdVisibility } from "react-icons/md";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useGlobalContext } from "../../context/context";
import { ToastContainer, toast } from "react-toastify";

const Login = () => {
  const [visible, setVisible] = useState(true);
  const [loading, setLoading] = useState(false);
  const [waitLoading, setWaitLoading] = useState(false);
  const name = "Elitecryptography";
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { baseUrl, getUser } = useGlobalContext();
  const toggleVisibility = () => setVisible(!visible);

  const navigate = useNavigate();

  const handleLogin = (data) => {
    setLoading(true);
    if (loading) {
      setTimeout(() => {
        setWaitLoading(true);
      }, 500);
    }
    axios
      .post(`${baseUrl}auth/login`, data)
      .then((data) => {
        setLoading(false);
        setWaitLoading(false);
        sessionStorage.setItem("user", JSON.stringify(data.data));
        sessionStorage.setItem(
          "accessToken",
          JSON.stringify(data.data.accessToken)
        );
        toast.success("Login Verified");
        getUser(data.data.accessToken, data.data._id);
        setTimeout(() => {
          navigate("/wallet");
        }, 2000);
      })
      .catch((error) => {
        setLoading(false);
        setWaitLoading(false);

        if (error.response.data === "Wrong credentials") {
          toast.error("Wrong Password or Email");
        }
      });
  };

  return (
    <>
      <Header />
      <section className="register_container login_container">
        <h2 className="text_2">Sign In</h2>
        <form
          className="register_form"
          onSubmit={handleSubmit((data) => handleLogin(data))}
        >
          <div>
            <p className="text_text">Email</p>
            <input
              type="text"
              className="form_input"
              placeholder="name@email.com"
              {...register("email", { required: "Email is Required" })}
            />
          </div>
          <div className="register_password form_input">
            <input
              type={visible ? "text" : "password"}
              placeholder="Your Password"
              {...register("password", { required: "Password is Required" })}
            />
            {visible ? (
              <AiOutlineEyeInvisible size={20} onClick={toggleVisibility} />
            ) : (
              <MdVisibility size={20} onClick={toggleVisibility} />
            )}
          </div>
          <div className="terms_container">
            <p className="terms">
              Don't have a {name} account?
              <Link to={"/auth/register"}> Create a new Account</Link>
            </p>
          </div>
          <Button
            title={
              loading ? "Loading..." : waitLoading ? "Please wait..." : "Login"
            }
            action={() => ""}
          />
        </form>
        <Link
          to={"/auth/forget-password"}
          className="text_text forgetPassword_text"
        >
          Forget your Password?
        </Link>
      </section>
      <ToastContainer />
      <Footer />
    </>
  );
};
export default Login;
