import { CryptoCurrencyMarket } from "react-ts-tradingview-widgets";

import "./Example.css";
import { Fragment } from "react";

export const Example = () => {
  return (
    <Fragment>
      <CryptoCurrencyMarket colorTheme="dark" width="100%" height={400} />
    </Fragment>
  );
};
