import React from "react";
import "./Button.css";

const Button = ({ icon, title, id, action }) => {
  return (
    <button
      onClick={() => {
        action(id);
      }}
      id={id}
      className="btn"
    >
      {title} {icon}
    </button>
  );
};

export default Button;
